import React from 'react';
import { useParams, Link } from 'react-router-dom';
import data from '../data.json';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function AppDetail() {
  const { id } = useParams();
  const app = data.apps.find(app => app.id === id);

  if (!app) {
    return <div className="container mx-auto px-4 py-8">App not found</div>;
  }

  const hasAppStoreVersion = app.appStoreLink && app.appStoreLink !== "";
  const hasGooglePlayVersion = app.googlePlayLink && app.googlePlayLink !== "";

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 5000
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">{app.name}</h1>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div>
          <img src={app.icon} alt={app.name} className="w-32 h-32 mb-4" />
          <p className="mb-4">{app.description}</p>
          <h2 className="text-2xl font-bold mb-2">Features</h2>
          <ul className="list-disc list-inside mb-4">
            {app.details.features.map((feature, index) => (
              <li key={index}>{feature}</li>
            ))}
          </ul>
          <div className="flex space-x-4">
            {hasAppStoreVersion && (
              <a href={app.appStoreLink} target="_blank" rel="noopener noreferrer" className="bg-blue-500 text-white px-4 py-2 rounded">App Store</a>
            )}
            {hasGooglePlayVersion && (
              <a href={app.googlePlayLink} target="_blank" rel="noopener noreferrer" className="bg-green-500 text-white px-4 py-2 rounded">Google Play</a>
            )}
          </div>
        </div>
        <div className="w-full max-w-[500px] mx-auto">
          <h2 className="text-2xl font-bold mb-2">Screenshots</h2>
          <Slider {...sliderSettings}>
            {app.screenshots.map((screenshot, index) => (
              <div key={index} className="flex justify-center items-center">
                <img 
                  src={screenshot} 
                  alt={`${app.name} screenshot ${index + 1}`} 
                  className="w-[500px] h-[500px] object-contain rounded-lg shadow-md" 
                />
              </div>
            ))}
          </Slider>
        </div>
        <div>
          <h2 className="text-2xl font-bold mt-4 mb-2">Technical Specifications</h2>
          <p>Platforms: {app.details.technicalSpecs.platforms.join(", ")}</p>
          {hasAppStoreVersion && (
            <p>Minimum iOS Version: {app.details.technicalSpecs.minimumOSVersion.ios}</p>
          )}
          {hasGooglePlayVersion && (
            <p>Minimum Android Version: {app.details.technicalSpecs.minimumOSVersion.android}</p>
          )}
          <p>Size: {app.details.technicalSpecs.size}</p>
          <h2 className="text-2xl font-bold mt-4 mb-2">Pricing</h2>
          <p>{app.details.pricing.free ? "Free to download" : "Paid app"}</p>
          {app.details.pricing.inAppPurchases.length > 0 && (
            <>
              <p className="font-bold mt-2">In-App Purchases:</p>
              <ul className="list-disc list-inside">
                {app.details.pricing.inAppPurchases.map((purchase, index) => (
                  <li key={index}>{purchase.name}: {purchase.price}</li>
                ))}
              </ul>
            </>
          )}
        </div>
      </div>
      <Link to="/projects" className="mt-8 inline-block bg-gray-500 text-white px-4 py-2 rounded">Back to Projects</Link>
    </div>
  );
}

export default AppDetail;