import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X } from 'lucide-react';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="bg-gray-800 text-white">
      <nav className="container mx-auto px-6 py-3">
        <div className="flex justify-between items-center">
          <Link to="/" className="text-xl font-bold">TechInnovate Solutions</Link>
          <div className="hidden md:flex space-x-4">
            <Link to="/projects" className="hover:text-gray-300">Projects</Link>
            <Link to="/contact" className="hover:text-gray-300">Contact Us</Link>
            <Link to="/privacy" className="hover:text-gray-300">Privacy Policy</Link>
            <Link to="/terms" className="hover:text-gray-300">Terms & Conditions</Link>
            <Link to="/eula" className="hover:text-gray-300">EULA</Link>
          </div>
          <button className="md:hidden" onClick={toggleMenu}>
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>
      </nav>
      {isMenuOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <Link to="/projects" className="block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-700 hover:text-white">Projects</Link>
            <Link to="/contact" className="block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-700 hover:text-white">Contact Us</Link>
            <Link to="/privacy" className="block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-700 hover:text-white">Privacy Policy</Link>
            <Link to="/terms" className="block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-700 hover:text-white">Terms & Conditions</Link>
            <Link to="/eula" className="block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-700 hover:text-white">EULA</Link>
          </div>
        </div>
      )}
    </header>
  );
}

export default Header;
