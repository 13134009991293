import React from 'react';
import { useLocation } from 'react-router-dom';
import data from '../data.json';

function PrivacyPolicy() {
  const location = useLocation();
  const appId = new URLSearchParams(location.search).get('appId');
  const app = data.apps.find(a => a.id === appId) || { name: 'our app' };

  const privacyPolicy = {
    title: "Privacy Policy",
    introductionTitle: "Introduction 🌟",
    introductionDescription:
      "Welcome to our Privacy Policy, which reflects our commitment to protecting your privacy and handling your data with care and respect.",
    dataCollectionTitle: "Data Collection 📊",
    dataCollectionDescription:
      "We collect various types of information, including personal information you provide and usage data, to provide and improve our services. Your email address is used only for account creation and communication purposes and is not shared with third parties. We do not collect or use your data for advertising purposes without your explicit consent.",
    dataUsageTitle: "Data Usage 🔍",
    dataUsageDescription:
      "Your data is used to personalize your experience within the app and to analyze app performance. We do not use your data for advertising purposes or share it with third parties.",
    dataProtectionTitle: "Data Protection 🔒",
    dataProtectionDescription:
      "We implement security measures to protect your data against unauthorized access, disclosure, alteration, and destruction.",
    userRightsTitle: "User Rights 🛡️",
    userRightsDescription:
      "You have the right to access, correct, and delete your personal information. You also have the right to object to or restrict certain types of data processing.",
    trackingTitle: "App Tracking Transparency 🔍",
    trackingDescription:
      "We respect your privacy choices. Our app uses the App Tracking Transparency framework to request your permission before tracking your activity across other companies' apps and websites for advertising purposes.",
    contactTitle: "Contact Us 📧",
    contactDescription:
      "If you have any questions or concerns about our privacy policy, please contact us at info@odel.dev.",
    privacyOptionsTitle: "Privacy Options",
    privacyOptionsDescription:
      "You can manage your privacy settings and opt-out of certain data collection practices within the app settings.",
  };

  return (
    <div className="container mx-auto px-6 py-12">
      <h1 className="text-4xl font-bold mb-12 text-center">{privacyPolicy.title} - {app.name}</h1>

      <div className="max-w-3xl mx-auto">
        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4">{privacyPolicy.introductionTitle}</h2>
          <p>{privacyPolicy.introductionDescription}</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4">{privacyPolicy.dataCollectionTitle}</h2>
          <p>{privacyPolicy.dataCollectionDescription}</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4">{privacyPolicy.dataUsageTitle}</h2>
          <p>{privacyPolicy.dataUsageDescription}</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4">{privacyPolicy.dataProtectionTitle}</h2>
          <p>{privacyPolicy.dataProtectionDescription}</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4">{privacyPolicy.userRightsTitle}</h2>
          <p>{privacyPolicy.userRightsDescription}</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4">{privacyPolicy.trackingTitle}</h2>
          <p>{privacyPolicy.trackingDescription}</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4">{privacyPolicy.contactTitle}</h2>
          <p>{privacyPolicy.contactDescription}</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4">{privacyPolicy.privacyOptionsTitle}</h2>
          <p>{privacyPolicy.privacyOptionsDescription}</p>
        </section>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
