import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import data from "../data.json";

function Home() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <div className="container mx-auto px-6 py-12">
      <section className="text-center mb-20" data-aos="fade-up">
        <h1 className="text-4xl font-bold mb-4">{data.company.name}</h1>
        <p className="text-xl">{data.company.description}</p>
      </section>

      <section className="mb-20" data-aos="fade-up">
        <h2 className="text-3xl font-bold mb-6">Our Projects</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {data.apps.map((app) => (
            <div key={app.id} className="bg-white rounded-lg shadow-md p-6" data-aos="fade-up">
              <Link to={`/app/${app.id}`}>
                <img src={app.icon} alt={app.name} className="w-20 h-20 mx-auto mb-4 cursor-pointer" />
              </Link>
              <h3 className="text-xl font-bold mb-2">{app.name}</h3>
              <p className="mb-4">{app.description}</p>
              <div className="flex justify-between items-center">
                <div>
                  {app.appStoreLink && (
                    <a href={app.appStoreLink} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline mr-4">App Store</a>
                  )}
                  {app.googlePlayLink && (
                    <a href={app.googlePlayLink} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">Google Play</a>
                  )}
                </div>
                <Link to={`/app/${app.id}`} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors">
                  Learn More
                </Link>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="text-center" data-aos="fade-up">
        <h2 className="text-3xl font-bold mb-6">About Us</h2>
        <p className="mb-4">Founded in {data.company.founded}</p>
        <p className="mb-4">Employees: {data.company.employees}</p>
        <p className="mb-4">Headquarters: {data.company.headquarters}</p>
        <Link to="/contact" className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600">
          Contact Us
        </Link>
      </section>
    </div>
  );
}

export default Home;
