import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import data from '../data.json';

function Projects() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  // Filter categories that have apps
  const categoriesWithApps = data.categories.filter(category => 
    data.apps.some(app => app.category === category)
  );

  return (
    <div className="container mx-auto px-6 py-12">
      <h1 className="text-4xl font-bold mb-12 text-center" data-aos="fade-up">Our Projects</h1>

      {categoriesWithApps.map((category) => {
        const appsInCategory = data.apps.filter(app => app.category === category);
        
        if (appsInCategory.length === 0) return null;

        return (
          <section key={category} className="mb-16" data-aos="fade-up">
            <h2 className="text-2xl font-bold mb-6">{category}</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {appsInCategory.map((app) => (
                <div key={app.id} id={app.id} className="bg-white rounded-lg shadow-md p-6" data-aos="fade-up">
                  <Link to={`/app/${app.id}`}>
                    <img src={app.icon} alt={app.name} className="w-20 h-20 mx-auto mb-4 cursor-pointer" />
                  </Link>
                  <h3 className="text-xl font-bold mb-2">{app.name}</h3>
                  <p className="mb-4">{app.description}</p>
                  <div className="flex justify-between items-center">
                    <div>
                      {app.appStoreLink && (
                        <a href={app.appStoreLink} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline mr-4">App Store</a>
                      )}
                      {app.googlePlayLink && (
                        <a href={app.googlePlayLink} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">Google Play</a>
                      )}
                    </div>
                    <Link to={`/app/${app.id}`} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors">
                      Learn More
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </section>
        );
      })}
    </div>
  );
}

export default Projects;