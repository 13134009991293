import React from 'react';
import { useLocation } from 'react-router-dom';
import data from '../data.json';

function TermsAndConditions() {
  const location = useLocation();
  const appId = new URLSearchParams(location.search).get('appId');
  const app = data.apps.find(a => a.id === appId) || { name: 'our app' };

  return (
    <div className="container mx-auto px-6 py-12">
      <h1 className="text-4xl font-bold mb-12 text-center">Terms and Conditions for {app.name}</h1>

      <div className="max-w-3xl mx-auto">
        <p className="mb-4">
          By downloading or using {app.name}, these terms will automatically apply to you – you should make sure therefore that you read them carefully before using the app.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Use of the App</h2>
        <p className="mb-4">
          You're not allowed to copy or modify the app, any part of the app, or our trademarks in any way. You're not allowed to attempt to extract the source code of the app, and you also shouldn't try to translate the app into other languages or make derivative versions.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Acceptable Use</h2>
        <p className="mb-4">
          You agree not to use {app.name} in a way that:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>Is unlawful, harmful, or fraudulent</li>
          <li>Infringes on anyone else's rights</li>
          <li>Interferes with the app's normal functioning</li>
        </ul>

        <h2 className="text-2xl font-bold mt-8 mb-4">Intellectual Property</h2>
        <p className="mb-4">
          {app.name} and its original content, features, and functionality are owned by {data.company.name} and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">User-Generated Content</h2>
        <p className="mb-4">
          Users may generate, post, or share content through {app.name}. By doing so, you grant {data.company.name} a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, adapt, publish, translate, and distribute your content.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Termination</h2>
        <p className="mb-4">
          We may terminate or suspend your access to the app immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Limitation of Liability</h2>
        <p className="mb-4">
          In no event shall {data.company.name}, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the app.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Governing Law</h2>
        <p className="mb-4">
          These Terms shall be governed and construed in accordance with the laws of Turkey, without regard to its conflict of law provisions.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Changes to These Terms</h2>
        <p className="mb-4">
          We reserve the right to modify these terms at any time. We will notify you of any changes by posting the new Terms on this page.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Contact Us</h2>
        <p className="mb-4">
          If you have any questions about these Terms, please contact us at {data.contact.email}.
        </p>
      </div>
    </div>
  );
}

export default TermsAndConditions;